/* NoScroll.css */
body, html {
    height: 100%;
    margin: 0;
    overflow: hidden; /* מניעת גלילה על כל הדף */
  }
  
  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  header, footer {
    flex: 0 0 auto; /* גובה קבוע להדר ולפוטר */
  }
  
  main {
    flex: 1; /* תופס את כל הגובה הנותר */
    overflow: auto; /* גלילה רק לחלק המיין */
  }
  