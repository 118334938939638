.afternoons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px; /* הפחתת הריווח הכללי של המיכל */
  font-family: Arial, sans-serif;
  width: 100%;
}

h1 {
  margin-bottom: 10px; /* הפחתת המרווח התחתון של הכותרת */
  color: #333;
}

.afternoons-table {
  width: 100%; /* השתנה ל-100% כדי להתרחב לרוחב מלא של המיכל */
  max-width: none; /* ניתן לקבוע גודל מקסימלי למניעת התפשטות יתר */
  border-collapse: collapse;
  margin: 10px 0; /* הפחתת המרווח העליון והתחתון של הטבלה */
  font-size: 18px;
  text-align: center;
}

.afternoons-table thead {
  background-color: #007bff; /* רקע לכותרות */
  color: #fff;
}

.afternoons-table th,
.afternoons-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.afternoons-table tbody tr {
  background-color: #fff;
  transition: background-color 0.3s;
}

.afternoons-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.afternoons-table tbody tr:hover {
  background-color: #f1f1f1;
}

button {
  padding: 8px 12px;
  font-size: 16px;
  color: #fff;
  background-color: #28a745; /* ירוק */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #218838; /* ירוק כהה */
}

.MuiMenuItem-root {
  text-align: right;
}

@media (max-width: 600px) {
  .MuiDataGrid-cell {
    padding: 0; /* צמצום ריווח פנימי בתאים */
  }

  .MuiButton-root {
    padding: 5px 10px; /* הקטנת גודל הכפתור */
    font-size: 0.8rem; /* הקטנת גודל הטקסט */
  }
}
