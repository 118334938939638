/* Footer.css */

.Footer {
  background-color: #bbb1b1;
  color: #272323;
  padding: 5px; /* Reduce padding to save space */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.Footer .section-1,
.Footer .section-3 {
  flex: 1; /* Allow sections to shrink and grow */
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer .section-3 img {
  max-width: 100%; /* Ensure image fits within its container */
  height: 30px; /* הקטנת גובה הלוגו */
}


.section-3:last-child {
  
  margin-left: auto; /* Move the last section (logo) to the right */
}



