/* App.css */

body, html {
  height: 100%;
  margin: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* תוקף את גובה הדף כולו */
}

header, .header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-bottom: 10px; /* ריווח תחתון */
  box-sizing: border-box; /* כולל את הריווח הפנימי בגובה */
  width: 100%; /* רוחב מלא */
}

main {
  flex: 1; /* תופס את כל השטח הפנוי בין ההדר לפוטר */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px; /* ריווח פנימי */
  box-sizing: border-box; /* כולל את הריווח הפנימי בגובה */
  width: 100%; /* רוחב מלא */
  overflow-y: auto; /* אפשר גלילה אם התוכן עולה על גובה ה-main */
}

.afternoons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* תופס את כל רוחב ה-main */
  max-width: 1200px; /* הגבל גודל מקסימלי אם רצוי */
 
  font-family: Arial, sans-serif;
  box-sizing: border-box; /* כולל את הריווח הפנימי וגבולות בתצורת הרוחב */
  padding: 20px; /* ריווח פנימי */
}

footer, .footer {
  height: 10%; /* גובה הפוטר */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-top: 10px; /* ריווח עליון */
  box-sizing: border-box; /* כולל את הריווח הפנימי בגובה */
  width: 100%; /* רוחב מלא */
  position: relative; /* קובע את הפוטר בתחתית */
}
/* App.css */

/* ... styles for your application ... */

@media (max-width: 600px) {
  .header img {
    height: 60px; /* Adjust logo size for smaller screens */
  }

  .Footer {
    padding: 10px;
  }

  .Footer .section-1,
  .Footer .section-3 {
    flex: 1;
    text-align: center; /* Center text in small screens */
  }

  .Footer .section-3 img {
    height: 30px; /* Adjust logo size for smaller screens */
  }
}

