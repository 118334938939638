/* Header.css */

.header-container {
  flex: 0 0 15%; /* גובה ההדר */
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  height: 10vh; /* Adjust height to fit your design */
}

.header {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  color: #271d1d;
  
  box-sizing: border-box;
  text-align: center; /* Ensure text is centered */
}

.header img {
  height: auto; /* Adjust height based on aspect ratio */
  max-width: 100%; /* Ensure it doesn't exceed container width */
  object-fit: contain;
}

.header .image {
  margin-left: 30px; /* Add space to the left of the second image */
}

.header h3 {
  margin: 0; /* Remove default margin for h3 */
  font-size: 1.5rem; /* Adjust font size as needed */
}
