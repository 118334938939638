.form {
    margin: 20px;
    font-family: 'Arial', sans-serif;
    direction: rtl;
    text-align: right;
  }
  
  .section-title {
    color: #4caf50; /* צבע ירוק כהה */
    border-bottom: 2px solid #4caf50; /* צבע ירוק כהה */
    display: inline-block;
    padding-bottom: 5px;
    margin-top: 20px;
  }
  
  .the-title {
    color: #4caf50; /* צבע ירוק כהה */
    border-bottom: 2px solid #4caf50; /* צבע ירוק כהה */
    display: block; /* או לא להוסיף כלום, ברירת מחדל היא block */
    text-align: center;
    padding-bottom: 5px;
    margin-top: 20px;
  }
  
  .form-container {
    max-width: 600px; /* גודל מקסימלי לטופס */
    margin: 0 auto; /* מרכז את הטופס */
    padding: 20px; /* מרווח פנימי */
    border: 1px solid #ccc; /* מסגרת */
    border-radius: 8px; /* פינות מעוגלות */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* צל להבלטה */
    background-color: #f9f9f9; /* רקע */
  }
  
  .form-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px; /* רווח בין הכפתורים */
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .right-align {
    text-align: right; /* יישור טקסט לימין */
    display: block; /* הצגת התווית כחסום */
    margin-bottom: 5px; /* מרווח בין התוויות */
    direction: rtl; /* כיוון הטקסט מימין לשמאל */
  }
  
  label {
    font-weight: bold;
    color: #24201f;
  }
  
  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    text-align: right;
  }
  
  button {
    align-self: center;
    padding: 10px 20px;
    border: none;
    background-color: #4caf50; /* צבע ירוק כהה */
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  button:hover {
    background-color: #388e3c; /* צבע ירוק כהה יותר */
  }
  
  .dialog-title {
    text-align: center;
  }
  
  /* נוספה עיצוב לכתובת, מייל וטלפון */
  .form-container .form-group input[type="text"],
  .form-container .form-group input[type="email"] {
    max-width: 100%;
  }
  
  /* סגנון ל-checkbox של הוספת שחקן נוסף */
  .form-group input[type="checkbox"] {
    margin-left: 10px;
  }
  
  /* סגנון ל-select */
  select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    margin-top: 5px;
  }

  .payment-info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .payment-info p {
    margin: 0;
    padding: 0;
  }
  
  h1, .payment-info {
    text-align: center;
  }

  .centered-button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
  }
  
  
  
  
  